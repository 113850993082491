body {
  background-color: white;
}

.table {
  width: 100%;
  margin-inline: 0px;
  table-layout: fixed;
}

table.dataTable thead tr {
  background-color: #4d95da;
  color: white;
  height: 30px;
  vertical-align: central;
  padding: 0px;
  margin: 0px;
  white-space: nowrap;
  font-size: 12px;
}

table.dataTable thead tr th {
  padding: 5px;
  margin: auto;
}

.dataTables_filter {
  float: right;
}

.dataTables_length {
  float: right;
}
div.dataTables_wrapper div.dataTables_length select {
  width: 60px;
}

.table.dataTable tbody tr.selected {
  background-color: #b0bed9;
}

.table.dataTable tbody > tr {
  vertical-align: middle;
  padding: 0px;
  margin: 0px;
  height: 30px;
  font-size: 12px;
}

.table tbody > tr > td {
  vertical-align: middle;
  align-content: center;
  padding: 0px;
  margin: 0px;
  padding-left: 5px;
  padding-right: 5px;
  align-items: center;
  /*to handle large texts*/
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

table.dataTable tr.selected td.select-checkbox:after {
  margin-top: -29px;
}

div.dataTables_wrapper div.dataTables_info {
  float: left;
  padding: 5px;
}

.td-tight {
  vertical-align: middle;
  align-content: center;
  padding: 0px;
  margin: auto;
  align-items: center;
}

.table tbody > tr > td > button {
  vertical-align: central;
  width: 60px;
  height: 20px;
  padding: 0px;
  margin: 4px;
  font-size: 12px;
}

.pagination > li {
  display: inline;
  padding: 0px !important;
  margin: 0px !important;
  border: none !important;
}

.div.dataTables_wrapper div.dataTables_length {
  width: 60px;
}

.column-bold {
  font-weight: bold;
}

.column-center {
  vertical-align: middle;
  align-content: center;
  text-align: center;
  padding: 0px;
  margin: 0px;
  white-space: nowrap;
}

.column-center-vertical {
  vertical-align: middle;
  align-content: center;
  text-align: center;
  padding: 0px;
  margin: 0px;
  display: flex;
}

.column-max-width-20 {
  width: 20%;
  max-width: 20%;
}

.ui-datepicker-header {
  background-color: #4d95da;
}

.btn {
  padding: 0px;
  margin: auto;
  width: 80px;
  height: 30px;
  vertical-align: central;
  text-align: center;
  align-content: center;
  font-size: 12px;
}

.btn-group-sm > .btn {
  padding: 0px;
  font-size: 12px;
}
.modal-header {
  background-color: #f47a30;
  color: white;
  height: 50px;
  padding: 5px;
  vertical-align: central;
  align-content: center;
}

.modal-title {
  padding: 5px;
  vertical-align: central;
  align-content: center;
}

.close {
  padding: 5px;
  margin: auto;
  width: 70px;
  vertical-align: central;
  align-content: center;
  color: white;
}

.td > .btn-primary {
  width: 70px;
  height: 30px;
  align-content: center;
  vertical-align: central;
  text-align: center;
  font-size: 12px;
}

.date-input {
  width: 200px;
  padding: 0px;
  margin: auto;
  background-color: white;
}

.text-input {
  background-color: #ffffff;
  height: 30px;
  vertical-align: central;
  font-size: 13px;
  /*min-width:120px;
    width:200px;*/
  text-align: center;
}

.caption-black {
  font-size: 13px;
  vertical-align: central;
  padding: 0px;
  margin: auto;
  width: fit-content();
}

.btn-green {
  background-color: limegreen;
  color: white;
  border-color: limegreen;
}

.div-search {
  padding: 0px;
  margin: auto;
  height: 50px;
  border-color: #dfdfdf;
}

.footer-fixed {
  position: relative;
  left: 0;
  bottom: 0;
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 30px;
  text-align: center;
  vertical-align: central;
  align-content: center;
  margin-top: -30px;
}

.vertical-center {
  min-height: 100%; /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* Fallback for browsers do NOT support vh unit */
  width: 100%;
  display: flex;
  align-items: center;
}

.page-main-container {
  padding: 10px 10px;
  margin: 0px;
}

.circleGreen {
  width: 15px;
  height: 15px;
  margin: auto;
  background-color: limegreen;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
}

.circleGrey {
  width: 15px;
  height: 15px;
  margin: auto;
  background-color: slategray;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
}

.circleRed {
  width: 15px;
  height: 15px;
  margin: auto;
  background-color: red;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
}

.div-padding {
  width: 100%;
  padding: 10px;
  margin: 0px;
}

.table-toolbar {
  vertical-align: central;
}

.toolbar-button {
  padding: 0px;
  margin: 0px;
  width: 60px;
  height: 25px;
  text-align: center;
}

.dataTables_wrapper .dataTables_length {
  float: right;
  margin-left: 10px;
}

.logo {
  width: 40px;
  height: 40px;
  align-content: center;
}
.logo-large {
  width: 65px;
  height: 65px;
  align-content: center;
}

.div-app-tile {
  vertical-align: middle;
  padding: 0px;
  margin: 0px;
  margin-top: auto;
  margin-bottom: 10px;
}

.label-title {
  padding: 0px;
  margin: auto;
  vertical-align: middle;
  align-content: center;
  font-size: 20px;
  color: #2a96c6;
}

.label-title2 {
  padding: 0px;
  margin: auto;
  vertical-align: unset;
  align-content: center;
  font-size: 16px;
  color: #2a96c6;
}

.div-vertical-center {
  width: 100%;
  text-align: center;
  text-align: center;
}

.login-page-cadre-title {
  font-size: 18px;
  font-weight: bold;
}

.login-page-footer-text {
  font-size: 12px;
}

.text-validation {
  font-size: 12px;
  color: red;
}

.text-invalid-credentials {
  font-size: 13px;
  font-weight: bold;
  color: red;
}

.datepicker {
  font-size: 13px;
}

.icon-svg {
  width: 20px;
  height: 20px;
  fill: white;
}

.modal-title {
  font-size: 16px;
  color: white;
  vertical-align: middle;
  padding: 0px;
  margin: auto;
  margin-left: 10px;
}

.modal-dialog {
  max-width: 900px;
  max-height: 300px;
}
.Value {
  height: 390px;
}

.modal-edit {
  max-width: 80%;
  max-height: 650px;
  height: 650px;
  width: 80%;
  margin: auto;
}

#modalConnections.modal-dialog {
  width: 700px; /* Occupy the 90% of the screen width */
  max-width: 700px;
}

.link-connection {
  font-size: 20px;
  height: 40px;
  vertical-align: middle;
  align-content: center;
}

/* 
.MuiDataGrid-colCellWrapper{
    background-color: skyblue;
    
}

.MuiDataGrid-columnsContainer{
    min-height: 35px;
    height: 35px;
}
.MuiDataGrid-window{
    top : 35px;
} */

.label-form {
  font-size: 13px;
  font-style: italic;
}

.input-form {
  font-size: 13px;
}

.dropdown {
  width: fit-content;
  margin: 0px;
  padding: 0px;
  height: fit-content;
  display: inline;
}

i {
  margin-right: 5px;
  margin-left: 5px;
}

/*for table loader*/
/* #loader {
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 0px;
    background-color: skyblue;
    color: springgreen;
}    */

.dataTables_wrapper .dataTables_processing {
  position: relative;
  text-align: center;
  font-size: 1.2em;
  background: steelblue;
  color: white;
  opacity: 70;
  z-index: 99;
}

.captcha-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tooltip {
  background-color: tan;
}
