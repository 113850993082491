.modal-dialog {
  width: 500px; /* Occupy the 90% of the screen width */
  max-width: 500px;
}

.connection-name {
  font-size: 14px;
  font-style: italic;
  padding-left: 10px;
}

.btn-connection {
  width: 40px;
  padding: 0px;
  margin: 0px;
  vertical-align: middle;
}

.GreenBorders {
  margin-bottom: -5.5%;

  height: 30px;
  border-radius: 10px;
  border: 2px solid #73ad21;
  background-color: limegreen;
}

.GreenText {
  padding: 10px;

  color: white;
  font-weight: bold;
}

.RedBorders {
  margin-bottom: -5.5%;

  height: 30px;
  border-radius: 10px;
  border: 2px solid red;
  background-color: red;
}

.RedText {
  padding: 10px;
  font-size: 100%;
  color: white;
  font-weight: bold;
}

.pauseIcon {
  display: inline-block;
  border-radius: 25px;
  box-shadow: 0px 3px 5px #888;
  padding: 0.5em;
  font-size: 10px;
}
