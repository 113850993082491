.modal-dialog {
    width: 1000px;    /* Occupy the 90% of the screen width */
    max-width: 1000px;
    
}

.btn-small {
    padding: 0px;
    margin: auto;
    width: 40px;
    max-width: 40px;
    height: 30px;
    vertical-align: central;
    text-align: center;
    align-content: center;
    font-size: 12px;
}
